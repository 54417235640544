<template>
  <div class="samSharp-Bold text">
      {{width}} x {{height}} <span class="tablet">v3</span>
        <!-- <svg  width="100%" height="100%">
            <line x1="0" y1="0" x2="10" y2="10" stroke="black" stroke-width="1px" vector-effect="non-scaling-stroke"> </line>
            <line x1="0" y1="10" x2="10" y2="0" stroke="black" stroke-width="1px" vector-effect="non-scaling-stroke"> </line>
        </svg> -->

        
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left config" viewBox="0 0 16 16" @click="$router.push('/')">
            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
  </div>


</template>

<script>
export default {
	data() {
        return {
                width : 0,
                height: 0,
            }
	},
	methods: {
        
    },
    mounted(){
        this.width = window.innerWidth
        this.height = window.innerHeight

        window.addEventListener('resize', ()=>{
            this.width = window.innerWidth
            this.height = window.innerHeight
        });
    }
}
</script>

<style>
.text{

    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    text-align: center;
    font-size: 50px;
    position: relative;
}
.config{
	color: #464444;
    position: fixed;
    top: 10px;
    right: 10px;
}
</style>