<template>
		<div class="win-screen">
			<div class="home-logo-container" >
				<img src="../assets/samsung-logo-svg.svg" class="samsung-logo" >
			</div>
			<h1 class="text-white samSharp-Medium">¡Ganaste!</h1>
			<p class="samOne-400">Has tenido 6 aciertos</p>
			<button class="samOne-700 btn-white" @click="$router.push('/')">Click para volver a empezar</button>
		</div>	
</template>

<script>

export default {
    name: 'WinScreen',
    data() {
	return {
			score : this.$route.query.score
		}
	},
}
</script>

<style lang="scss">
    @import '~@/styles/base.scss';
    @import '~@/styles/winscreen.scss';
</style>