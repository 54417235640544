<template>
	<div class="game-screen">
		<h1 class="text-black samSharp-Bold">Descubrí en 1’ nuestros productos</h1>
		<div class="cards">
			<div class="card" v-bind:key="item" v-for="(card , item) in cards" :class="{ flipped: card.flipped, found: card.found }" @click="flipCard(card)">
				<div class="back"></div>
				<div class="front" :style="{ backgroundImage: 'url(' + card.image + ')' }"></div>
			</div>

			<div class="info">
				<div><span class="label samOne-400 text-black">Aciertos:</span><span class="value turns samOne-700">{{ hits }}</span></div>
				<div><span class="value time samOne-700">{{ time }} </span></div>
			</div>
		</div>
	</div>
</template>

<script>

import _ from 'lodash'
import moment from 'moment'
	
	let CardTypes = [
		{ name: "UHD4K", image: "./img/UHD4K.png" },
		{ name: "GalaxyS21", image: "./img/GalaxyS21.png" },
		{ name: "GalaxyS21Ultra", image: "./img/GalaxyS21Ultra.png" },
		{ name: "TabS7Plus", image: "./img/TabS7Plus.png" },
		{ name: "GalaxyWatch4", image: "./img/GalaxyWatch4.png" },
		{ name: "GalaxyBud2", image: "./img/GalaxyBud2.png" },
		

		// { name: "babel", image: "https://babeljs.io/images/logo.svg" },
		//{ name: "jade", image: "http://jade-lang.com/style/jade-logo-header.svg" },
	]
	let shuffleCards = () => {
		let cards = [].concat(_.cloneDeep(CardTypes), _.cloneDeep(CardTypes));
		return _.shuffle(cards);
	}
export default {
	data() {
	return {
			countDownDate :  moment().add(10, 'seconds'),
			countDownTimer : null,
			hits : 0,

			showSplash: false,
			cards: [],
			started: false,
			startTime: 0,
			turns: 0,
			flipBackTimer: null,
			timer: null,
			time: "01:00",
			score: 0
		}
	},
	
	methods: {
		resetGame() {
			
			this.showSplash = false;
			let cards = shuffleCards();
			this.turns = 0;
			this.score = 0;
			this.started = false;
			this.startTime = 0;
			
			_.each(cards, (card) => {
				card.flipped = false;
				card.found = false;
			});
			
			this.cards = cards;
		},
		
		flippedCards() {
			return _.filter(this.cards, card => card.flipped);
		},
		
		sameFlippedCard() {
			let flippedCards = this.flippedCards();
			if (flippedCards.length == 2) {
				if (flippedCards[0].name == flippedCards[1].name)
					return true;
			}
		},
		
		setCardFounds() {
			_.each(this.cards, (card) => {
				if (card.flipped)
					card.found = true;
			});
		},
		
		checkAllFound() {
			let foundCards = _.filter(this.cards, card => card.found);
			if (foundCards.length == this.cards.length)
				return true;
		},
		
		startGame() {
			this.started = true;
			this.startTime = moment()

			

			// this.timer = setInterval(() => {
			// 	this.time = moment(moment().diff(this.startTime)).format("mm:ss");
			// }, 1000);

			// setup start time
			this.countDownDate = moment().add(61, 'seconds')
			
			this.countDownTimer = setInterval( () => {
				let diff = this.countDownDate.diff(moment())
				if (diff <= 0) {
					clearInterval(this.countDownTimer);
					// If the count down is finished, write some text 
					console.log("Perdiste Enviar  a la pagina de los looooooosers")

					window.location.href = `/#/loose`;
				} else{
					this.time = moment.utc(diff).format("mm:ss")
					console.log(moment.utc(diff).format("mm:ss"))
				}				
			}, 1000)
			
		},
		
		// Ganó el juego
		finishGame() {
			this.started = false;
			clearInterval(this.timer);
			let score = 1000 - (moment().diff(this.startTime, 'seconds') - CardTypes.length * 5) * 3 - (this.turns - CardTypes.length) * 5;
			this.score = Math.max(score, 0);
			this.showSplash = true;
			
			clearInterval(this.countDownTimer)
			setTimeout(() => {
				window.location.href = `/#/win?score=${score}`	
			}, 500);
		},
		
		flipCard(card) {
			if (card.found || card.flipped) return;
			
			if (!this.started) {
				this.startGame();
			}
			
			let flipCount = this.flippedCards().length;
			// Modificar para que cuente los aciertos
			// this.turns = this.cards.filter((c)=>{  })
			

			if (flipCount == 0) {
				card.flipped = !card.flipped;
			}
			else if (flipCount == 1) {
				card.flipped = !card.flipped;
				//this.turns += 1;

				if (this.sameFlippedCard()) {
					// Match!
					this.flipBackTimer = setTimeout( ()=> {
						this.clearFlipBackTimer();
						this.setCardFounds();
						this.clearFlips();

						if (this.checkAllFound()) {
							this.finishGame();
						}	
						this.getHits()
					}, 200);
				}
				else {
					// Wrong match
					this.flipBackTimer = setTimeout( ()=> {
						this.clearFlipBackTimer();
						this.clearFlips();
						this.getHits()
					}, 1000);
				}
			}
			this.getHits()
		},
		
		clearFlips() {
			_.map(this.cards, card => card.flipped = false);
		},
		
		
		clearFlipBackTimer() {
			clearTimeout(this.flipBackTimer);
			this.flipBackTimer = null;
		},

		getHits(){
			var hits = _.map(this.cards, (o)=>{
    			if (o.found == true) return o;
			})

			// Remove undefines from the array
			this.hits = (_.without(hits, undefined).length)? _.without(hits, undefined).length / 2  : 0 
			
			}
	},
	
	created() {
		this.resetGame();
	}

}
</script>

<style lang="scss">
    @import '~@/styles/base.scss';
    @import '~@/styles/game.scss';
	// .tablet{
	// 		color : black;
	// 	}
	// @media (max-width: 970px) {
	// 	.cards .card {
	// 		width: 144px;
	// 		height: 144px;
	// 	}
	// 	.tablet{
	// 		color : green;
	// 	}
	// }

	// @media (max-width: 1020px) {
	// 	.cards .card {
	// 		width: 144px;
	// 		height: 144px;
	// 	}
	// 	.tablet{
	// 		color : red;
	// 	}
	// }
</style>