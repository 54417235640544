<template>
		<div class="loose-screen">
            <div class="home-logo-container" >
                <img src="../assets/samsung-logo-svg.svg" class="samsung-logo" >
            </div>
        	<h1 class="text-white samSharp-Medium">Se ha terminado el tiempo</h1>
			<p class="samOne-400">y no has tenido aciertos. ¡Hasta la próxima!</p>
			<button class="samOne-700 btn-white" @click="$router.push('/')">Click para volver a empezar</button>
		</div>	
</template>

<script>

export default {
    name: 'LooseScreen',
    data() {
	return {
			score : this.$route.query.score
		}
	},
}
</script>

<style lang="scss">
    @import '~@/styles/base.scss';
    @import '~@/styles/loosescreen.scss';
</style>